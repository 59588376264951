<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div v-if="type === 'create'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderSize_NewSizePopup'])"></span>
        {{$t('orderSize_NewSizePopup.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['orderSize_EditSizePopup'])"></span>
        {{$t('orderSize_EditSizePopup.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['orderSize_EditSizePopup'])"></span>
            <InputSum
                :icoType="navTabs.metric.active ? 'cm' : 'inc'"
                :label="$t('orderSize_Size.localization_value.value')"
                v-bind:class="{'ui-no-valid': OS.validation.size}"
                :errorTxt="$t(`${OS.validationTranslate.size}.localization_value.value`)"
                :error="OS.validation.size"
                v-model="OS.data.size"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import {OrderSize} from "@/components/modules/OrderSizeModule/models/OrderSize";

  export default {
    name: "OrderSizePopup",
    components: {
      Modal,
      MainButton,
      InputSum,
    },

    props: {
      type: {
        type: String,
        default: 'create'
      },
      modalOrderSizeId: Number,
      navTabs: Object,
    },

    data() {
      return {
        OS: new OrderSize(),
      }
    },

    mounted() {
      if (this.type !== 'create') {
        this.$store.dispatch('getOrderSize', this.modalOrderSizeId).then(response => {
          let data = this.getRespData(response)
          this.OS.setItem(data)
        }).catch(error => this.createErrorLog(error))
      } else {
        this.OS.setSizeType(this._.find(this.navTabs, {active: true}).value)
      }
    },

    methods: {
      save() {
        if(!this.OS.firstValidationUser()) return

        let data = this.OS.prepareSave()
        let typeDispatch = 'createOrderSize'

        if (this.type !== 'create') {
          typeDispatch = 'updateOrderSize'
          data = {
            data: data,
            id: this.modalOrderSizeId
          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if(this.OS.data.useDefault){
                this.$store.dispatch('setOrderSize', this.modalOrderSizeId).then(() => {
                  if (this.type === 'create') {
                    this.openNotify('success', 'common_notificationRecordCreated')
                  } else {
                    this.openNotify('success', 'common_notificationRecordChanged')
                  }
                  this.$emit('close')
                  this.$emit('reload')
                })
                return
              }

              if (this.type === 'create') {
                this.openNotify('success', 'common_notificationRecordCreated')
              } else {
                this.openNotify('success', 'common_notificationRecordChanged')
              }
              this.$emit('close')
              this.$emit('reload')

              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    }
  }
</script>

<style scoped>

</style>
