import {checkValid} from "../../../globalModels/functions/ModelsValidate";

export function OrderSize() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    size: '',
    sizeType: '',
  }

  this.validation = {
    id: false,
    size: false,
    sizeType: false,
  }

  this.validationTranslate = {
    id: '',
    size: '',
    sizeType: '',
  }

  this.validationTxt = {
    id: false,
    size: false,
    sizeType: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getSize = () => {
    return this.data.size
  }
  this.getSizeType = () => {
    return this.data.sizeType
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setSize = (val) => {
    this.data.size = val
  }
  this.setSizeType = (val) => {
    this.data.sizeType = val
  }
}

/**
 * Global Setters
 */

OrderSize.prototype.setItem = function (item) {
  this.setId(item?.id)
  this.setSize(parseFloat(item?.size).toFixed(2))
  this.setSizeType(item?.size_type)
}

/**
 * Prepare Data
 */

OrderSize.prototype.prepareSave = function () {
  return {
    'size': this.getSize(),
    'size_type': this.getSizeType(),
  }
}

/**
 * Validations
 */

OrderSize.prototype.firstValidationUser = function () {

  let validationItems = {
    size: this.getSize(),
    sizeType: this.getSizeType(),
  }

  let validationOptions = {
    size: {type: ['empty']},
    sizeType: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions))
}
