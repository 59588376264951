<template>
  <div>
    <OrderSizeTableHead
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
      <OrderSizeTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @deleteOrder="(id) => $emit('deleteOrder', id)"
      />
    </div>
  </div>
</template>

<script>


  import OrderSizeTableHead from "./OrderSizeHead/OrderSizeHead";
  import OrderSizeTable from "./OrderSizeTable/OrderSizeTable";

  export default {
    name: "OrderSizeTableAdmin",

    components: {
      OrderSizeTableHead,
      OrderSizeTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

    }
  }
</script>

<style scoped>

</style>
