
<template>
	<button class="case-cart-button"
					:class="{
						'main-button--tooltip' : tooltip == true,
						'disabled' : disabled == true
					}"
					:disabled="disabled"
	>
		<div class="case-cart-button__ico" v-if="ico">
			<slot name="ico">

			</slot>


			<EditIconWhite class="mb-1" v-if="typeIco === 'edit'"/>
			<CopyIconWhite v-if="typeIco === 'copy'"/>
			<CodeIconWhite v-if="typeIco === 'code'"/>
			<DeleteIconBrown v-if="typeIco === 'delete-brown'"/>
			<ArchiveIconWhite v-if="typeIco === 'archive'"/>
			<ArchiveIconBrown v-if="typeIco === 'archive-brown'"/>
			<ShowIconWhite v-if="typeIco === 'show'"/>
		</div>
		{{value}}

		<span
				v-if="tooltip"
				class="case-cart-button__tooltip"
		>
      <v-popover
					class="site-tooltip"
					:disabled="!tooltipActive"
					offset="5"
					placement="top"
					trigger="hover"

			>
        <TooltipBtn
						class="tooltip-target"

				/>
          <template slot="popover">
            <slot name="tooltip">

            </slot>
            <a v-close-popover class="tooltip__close"></a>
          </template>
      </v-popover>
    </span>
	</button>
</template>

<script>
	import { VPopover  } from 'v-tooltip'
	import TooltipBtn from "../../tooltips/TooltipBtn/TooltipBtn.vue"
	import EditIconWhite from "../../../../../public/img/UI-group/edit-icon-white_.svg?inline"
	import CopyIconWhite from "../../../../../public/img/UI-group/copy-icon-white.svg?inline"
	import CodeIconWhite from "../../../../../public/img/UI-group/code-icon-white.svg?inline"
	import DeleteIconBrown from "../../../../../public/img/UI-group/delete-icon-brown.svg?inline"
	import ArchiveIconWhite from "../../../../../public/img/UI-group/archive-icon-white.svg?inline"
	import ArchiveIconBrown from "../../../../../public/img/UI-group/archive-icon-brown.svg?inline"
	import ShowIconWhite from "../../../../../public/img/common/pass-show.svg?inline"


	export default {
		name: "CaseCartButton",

		components: {
			VPopover,
			TooltipBtn,
			EditIconWhite,
			CopyIconWhite,
			CodeIconWhite,
			DeleteIconBrown,
			ArchiveIconWhite,
      ArchiveIconBrown,
			ShowIconWhite,
		},

		data () {
			return {
				id: null,
				isShowPass: false,
				tooltipActive: true,
			}
		},

		props: [
			'value',
			'ico',
			'tooltip',
			'plus',
			'typeIco',
			'disabled',

		],

		mounted () {

		},

		methods: {

		},
	}
</script>

<style lang="scss">
	@import "../../../../scss/mixins/mixins";
	@import "../../../../scss/colors";

	.case-cart-button{
		display: flex;
		height: 43px;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: $accent;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
		outline: 0;
		transition: 0.3s;
		padding: 0 15px;
		position: relative;
		border: 0;
		border-radius: 0;
		font-size: 14px;
		line-height: 16px;



		&--brown{
			background: $brown;
			border-color: $brown;
		}

		&:hover{
			background: $brown;
			border-color: $brown;
		}

		&.secondary-brown{
			color: $brown;
			background: transparent;
		}


		&.secondary{
			color: $accent;
			border-color: $accent;
			background: transparent;

			&:hover{
				border-color: $brown;
				color: $brown;
			}
		}


		&__ico{
			/*width: 19px;*/
			/*min-width: 19px;*/
			/*height: 19px;*/
			/*max-width: 16px;*/
			margin-right: 5px;


			width: 19px;
			min-width: 19px;
			height: 19px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg{
				width: 100%;
			}
		}

		&.disabled{
			background: $borderBrown;
			opacity: .7;
		}

		&--tooltip{
			max-width: 100%;
			width: fit-content;
			padding: 0 24px;

			&:hover{
				svg circle {
					fill: #8F7A61;
				}
			}
		}

		&__tooltip{
			position: absolute;
			top: 2px;
			right: 2px;
		}
	}
</style>
