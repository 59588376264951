<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

    </div>

    <div class="horiz-block mt-4 mb-4">
      <div class="horiz-block__inner d-flex justify-content-end">
<!--        <template-->
<!--            v-if="isOtherPrices">-->
<!--          <div-->
<!--              v-if="navTabs[POSTNL_SHIPPING_COMPANY.name].active"-->
<!--              class="mt-2 mr-3"-->
<!--              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['orderPrice_importPriceMask'])"></span>-->
<!--            <TableLeftBlockBtn-->
<!--                class="btn-left-block-i&#45;&#45;link"-->
<!--                :value="$t('orderPrice_importPriceMask.localization_value.value')"-->
<!--                :type="'transfer'"-->
<!--                @click.native="importPriceMask"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="mt-2 mr-auto"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['orderPrice_importPrice'])"></span>-->
<!--            <TableLeftBlockBtn-->
<!--                class="btn-left-block-i&#45;&#45;link"-->
<!--                :value="$t('orderPrice_importPrice.localization_value.value')"-->
<!--                :type="'transfer'"-->
<!--                @click.native="importPrice"-->
<!--            />-->
<!--          </div>-->
<!--        </template>-->


        <div
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['orderSize_NewSize'])"></span>
          <MainButton class="white-space-nowrap scoped-btn"
                      :value="'+ ' + $t('orderSize_NewSize.localization_value.value')"
                      @click.native="changeOrderSizeCreatePopup(true)"
          >
          </MainButton>
        </div>

        <div
            class="ml-3"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--          <router-link :to="this.$store.getters.GET_PATHS.generatePdfFile + '/order-size?type=pdf&sizeType=' + getType"-->
<!--                       :target="'_blank'"-->
<!--          >-->
            <span class="admin-edit" @click="editTranslate(['orderSize_print'])"></span>
            <MainButton class="white-space-nowrap scoped-btn"
                        :value="$t('orderSize_print.localization_value.value')"
                        @click.native="printOrderSize"
            >
            </MainButton>
<!--          </router-link>-->
        </div>
      </div>
    </div>

    <OrderSizePopup
      v-if="isModalOrderSizeCreatePopup"
      :navTabs="navTabs"
      @close="changeOrderSizeCreatePopup(false)"
      @reload="$emit('reload')"
    />

<!--    <ImportOrderSizePopup-->
<!--        v-if="isModalImportPopup"-->
<!--        :navTabs="navTabs"-->
<!--        :typeZip="importTypeZip"-->
<!--        @close="isModalImportPopup = false"-->
<!--        @reload="$emit('reload')"-->
<!--    />-->
  </div>
</template>



<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import OrderSizePopup from "../../../../popups/OrderSizePopup/OrderSizePopup";
  // import TableLeftBlockBtn from "@/components/coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";
  // import ImportOrderSizePopup from "@/components/modules/OrderSizeModule/popups/ImportOrderSizePopup/ImportOrderSizePopup";


  export default {
    name: "OrderSizeHead",
    components: {
      // ImportOrderSizePopup,
      // TableLeftBlockBtn,
      MainButton,
      // SearchEngine,
      OrderSizePopup,
    },

    props: {
      navTabs: Object,
      isOtherPrices: Boolean,
    },

    computed: {
      getType() {
        return this._.find(this.navTabs, {active: true}).value
      },
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
        input01: '',
        options: [{}],
        isModalOrderSizeCreatePopup: false,
        isModalOrderOtherPriceCreatePopup: false,
        isModalImportPopup: false,

        importTypeZip: false,
      }
    },

    methods: {

      printOrderSize() {
        this.$store.dispatch('getOrderSizeFile', {type: 'docx', sizeType: this.getType}).then(response => {
          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, 'Order Size.docx')
        }).catch(error => this.createErrorLog(error))
      },

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeOrderSizeCreatePopup(val){
        this.isModalOrderSizeCreatePopup = val
      },

      importPrice() {
        this.importTypeZip = false
        this.isModalImportPopup = true
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";
  @import "../../../../../../../scss/colors";

  .scoped-btn{
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }
</style>

