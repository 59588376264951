<template>
  <OrderSizeTableAdmin
      :navTabs="navTabs"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
      @deleteOrder="(id) => deleteOrder(id, true)"
  />
</template>

<script>
  import OrderSizeTableAdmin from "./OrderSizeTableAdmin/OrderSizeTableAdmin";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "OrderSizeTable",
    components: {
      OrderSizeTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getOrderSizeForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterId: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          metric: {
            active: true,
            label: 'orderSize_metric',
            name: 'metric',
            value: 'metric',
            permissionVisible: true,
          },
          imperial: {
            active: false,
            label: 'orderSize_imperial',
            name: 'imperial',
            value: 'imperial',
            permissionVisible: true,
          },
        },
      }
    },


    mounted() {
      if(this.$route.query.type !== undefined){
        this.navTabs.metric.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      this.filter()
    },

    methods: {


      deleteOrder() {

      },


      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrderSizePage', true)
        this.$store.dispatch('fetchOrderSizeWithPermissions', url).then(() => {
          this.$store.commit('setNextOrderSizePage', false)
        })

        this.checkCountFilter(['type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if(this.filterType === '') {
          this.filterType = this.navTabs.metric.value
        }

        this.generateFilterQueryParams(
            myQuery,
            {
              filterType: 'bySizeType',
            },
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },
    },


  }
</script>

<style scoped>

</style>